.form-contact{
    text-align: center;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width:300px;
    z-index: 7;
  }

.contact {
    width: 100vw;
    height: 100vh;
    color: #fff;
    display:flex;
    justify-content: center;
    background-size: cover;
    font-family: Muli, sans-serif;
}

.container_contact {
  
    padding-top: 50px;
    
}

.form-contact input {
    width:250px;
}

.form-contact textarea {
    width:250px;
    height:200px;
    overflow-y: scroll;
}