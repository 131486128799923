.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.action-left {

  color:#000;
  border-radius: 20px;
  padding: 12px;
  background: rgba(255, 255, 255, 1);
  border: #000 2px solid;
  position:relative;
  max-width:500px;
  font-family: Muli, sans-serif;

}

.action-left:before {
  content: "";
  position: absolute;
  border-right: 20px solid rgba(255, 255, 255, 1);
  border-left: 20px solid transparent;
  border-top: 20px solid rgba(255, 255, 255, 1);
  border-bottom: 20px solid transparent;
  left: -25px;
  top: 10px;
}

.action {

  color:#000;
  border-radius: 20px;
  padding: 12px;
  background: rgba(255, 255, 255, 1);
  border: #000 2px solid;
  position:relative;
  max-width:500px;
  font-family: Muli, sans-serif;
 
}


.action-right {

  color:#000;
  border-radius: 20px;
  padding: 12px;
  background: rgba(255, 255, 255, 1);
  border: #000 2px solid;
  position:relative;
  max-width:500px;
  font-family: Muli, sans-serif;

}

.action-right:before {
  content: "";
  position: absolute;
  border-left: 20px solid rgba(255, 255, 255, 1);
  border-right: 20px solid transparent;
  border-top: 20px solid rgba(255, 255, 255, 1);
  border-bottom: 20px solid transparent;
  right: -25px;
  top: 10px;
}

@media only screen and (max-width: 800px) {

  .action, .action-left, .action-right
  {
    max-width:50%;
    min-width:50%;
    min-height:25px;
  }

  .action-right:before {
    top: 7px;
    right: -30px;
  }


  .action-left:before {
    top: 7px;
    left: -30px;
  }

}

#badge-star {
  position: relative;
  color: rgb(0, 122, 39);
  background: url("/star-anim.gif") no-repeat center;
  background-size: contain;
  margin:10px;
  text-align: center;
  padding:25px;
  height: 50px;
  width: 50px;
  font-size: 14px;
  font-weight: 600;
  text-shadow: #000 1px 1px;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  animation: Grow 0.5s infinite; 
}

@keyframes Grow {
  from {
    transform: scale(0.1);

  }
  to {
    transform: scale(1);
   
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
    
  }
  to {
    transform: rotate(360deg);
  }
}


.megalogo  {
  margin: 0;
  padding: 0;
  text-align: center;
  font-family: sporadic;
  font-size:5em;
  display:flex;
  align-items:center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 2px; 
  /* mix-blend-mode: multiply; */
  text-decoration: none;
  color: rgba(97, 28, 10, 0.9);
    text-shadow: 1px 1px rgba(0, 0, 0, 0.3);    
  
  /* background-image: url('/darkmetal.jpeg');
  background-size:cover; */
  background-repeat: repeat-y;
  background:
  -moz-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #caa1de 27%,
    #dea1ca 30%,
    #dedede 38%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #caa1de 93%,
    #dea1ca
  );
  background:
  -webkit-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #caa1de 27%,
    #dea1ca 30%,
    #dedede 38%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #caa1de 93%,
    #dea1ca
  );
  background:
  -o-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #caa1de 27%,
    #dea1ca 30%,
    #dedede 38%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #caa1de 93%,
    #dea1ca
  );
  background:
  linear-gradient(
    0deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #caa1de 27%,
    #dea1ca 30%,
    #dedede 38%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #caa1de 93%,
    #dea1ca
  );

  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.2);
  }
  
  .megalogo2  {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: sporadic;
    font-size:5em;
    text-transform: uppercase;
    letter-spacing: 2px; 
    /* mix-blend-mode: multiply; */
    text-decoration: none;
    color: rgba(97, 28, 10, 0.9); 
    
    /* background-image: url('/darkmetal.jpeg');
    background-size:cover; */
    background:
    -moz-linear-gradient(
      -72deg,
      #ca7345,
      #ffdeca 16%,
      #ca7345 21%,
      #ffdeca 24%,
      #a14521 27%,
      #ca7345 36%,
      #ffdeca 45%,
      #ffdeca 60%,
      #ca7345 72%,
      #ffdeca 80%,
      #ca7345 84%,
      #732100
    );
    background:
    -webkit-linear-gradient(
      -72deg,
      #ca7345,
      #ffdeca 16%,
      #ca7345 21%,
      #ffdeca 24%,
      #a14521 27%,
      #ca7345 36%,
      #ffdeca 45%,
      #ffdeca 60%,
      #ca7345 72%,
      #ffdeca 80%,
      #ca7345 84%,
      #732100
    );
    background:
    -o-linear-gradient(
      -72deg,
      #ca7345,
      #ffdeca 16%,
      #ca7345 21%,
      #ffdeca 24%,
      #a14521 27%,
      #ca7345 36%,
      #ffdeca 45%,
      #ffdeca 60%,
      #ca7345 72%,
      #ffdeca 80%,
      #ca7345 84%,
      #732100
    );
    background:
    linear-gradient(
      0deg,
      #ca7345,
      #ffdeca 16%,
      #ca7345 21%,
      #ffdeca 24%,
      #a14521 27%,
      #ca7345 36%,
      #ffdeca 45%,
      #ffdeca 60%,
      #ca7345 72%,
      #ffdeca 80%,
      #ca7345 84%,
      #732100
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-display: swap;
    }
    

.metallic  {
margin: 0;
padding: 0;
text-align: center;
font-family: bgf7;
font-size:5em;
font-weight: normal;
font-style: normal;
text-transform: uppercase;
letter-spacing: 2px; 
text-decoration: none;
color: rgba(97, 28, 10, 0.9);
background-repeat: repeat-y;
background:
-moz-linear-gradient(
  -72deg,
  #ffdeca,
  #ffffff 16%,
  #ffdeca 21%,
  #ffffff 24%,
  #de7345 27%,
  #ffdeca 36%,
  #ffffff 45%,
  #ffffff 60%,
  #ffdeca 72%,
  #ffffff 80%,
  #ffdeca 84%,
  #de7345
);
background:
-webkit-linear-gradient(
  -72deg,
  #ffdeca,
  #ffffff 16%,
  #ffdeca 21%,
  #ffffff 24%,
  #de7345 27%,
  #ffdeca 36%,
  #ffffff 45%,
  #ffffff 60%,
  #ffdeca 72%,
  #ffffff 80%,
  #ffdeca 84%,
  #de7345
);
background:
-o-linear-gradient(
  -72deg,
  #ffdeca,
  #ffffff 16%,
  #ffdeca 21%,
  #ffffff 24%,
  #de7345 27%,
  #ffdeca 36%,
  #ffffff 45%,
  #ffffff 60%,
  #ffdeca 72%,
  #ffffff 80%,
  #ffdeca 84%,
  #de7345
);
background:
linear-gradient(
  180deg,
  #ffdeca,
  #ffffff 16%,
  #ffdeca 21%,
  #ffffff 24%,
  #de7345 27%,
  #ffdeca 36%,
  #ffffff 45%,
  #ffffff 60%,
  #ffdeca 72%,
  #ffffff 80%,
  #ffdeca 84%,
  #de7345
);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}


@font-face {
  font-family: boxer;
  src: url(/fonts/BlackBoxer-Regular.woff);
}

@font-face {
  font-family: sporadic;
  src: url(/fonts/Sporadic.ttf);
}

@font-face {
  font-family: bgf7;
  src: url(/fonts/bold_game_font_7.ttf);
}


.button-platinum {
  color: rgba(0, 0, 0, 0.72);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5)
  ;
  border: 1px solid #cacade;
  background:
    -moz-linear-gradient(
    -72deg,
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #a2a2bb 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
  background:
  -webkit-linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #a2a2bb 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
  background:
  -o-linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #a2a2bb 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
  background:
  linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #a2a2bb 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
}

.button-whitegold {
  color: rgba(82, 82, 34, 0.72);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffdeca;
  box-shadow:
    2px 2px 0.5em rgba(155, 122, 89, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5)
  ;
  border: 1px solid #caa173;
  background:
  -moz-linear-gradient(
    -72deg,
    #ffdea1,
    #ffffff 16%,
    #ffdea1 21%,
    #ffffff 24%,
    #736445 27%,
    #ffdea1 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffdea1 72%,
    #ffffff 80%,
    #ffdea1 84%,
    #736445
  );
  background:
  -webkit-linear-gradient(
    -72deg,
    #ffdea1,
    #ffffff 16%,
    #ffdea1 21%,
    #ffffff 24%,
    #736445 27%,
    #ffdea1 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffdea1 72%,
    #ffffff 80%,
    #ffdea1 84%,
    #736445
  );
  background:
  -o-linear-gradient(
    -72deg,
    #ffdea1,
    #ffffff 16%,
    #ffdea1 21%,
    #ffffff 24%,
    #736445 27%,
    #ffdea1 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffdea1 72%,
    #ffffff 80%,
    #ffdea1 84%,
    #736445
  );
  background:
  linear-gradient(
    -72deg,
    #ffdea1,
    #ffffff 16%,
    #ffdea1 21%,
    #ffffff 24%,
    #736445 27%,
    #ffdea1 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffdea1 72%,
    #ffffff 80%,
    #ffdea1 84%,
    #736445
  );
}


.button-carbon {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.45);
  border: 1px solid #565656;
  box-shadow:
    2px 2px 0.5em rgba(0, 0, 0, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.55),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5)
  ;
  background:
    -moz-repeating-linear-gradient(
    top, #565656, #131313 2px, #565656 1px
  );
  background:
    -o-repeating-linear-gradient(
    top, #565656, #131313 2px, #565656 1px
  );
  background:
    -webkit-repeating-linear-gradient(
    top, #565656, #131313 2px, #565656 1px
  );
  background:
    repeating-linear-gradient(
    top, #565656, #131313 2px, #565656 1px
  );
}


.button-silver {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.34)
  ;
  border: 1px solid #dedede;
  background:
    -moz-linear-gradient(
    -100deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #454545 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
  background:
  -webkit-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #454545 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
  background:
  -o-linear-gradient(
    -72deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #454545 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
  background:
  linear-gradient(
    -180deg,
    #dedede,
    #ffffff 16%,
    #dedede 21%,
    #ffffff 24%,
    #454545 27%,
    #dedede 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedede 72%,
    #ffffff 80%,
    #dedede 84%,
    #a1a1a1
  );
}

.button-pinkgold {
  color: rgba(56, 13, 0, 0.64);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(122, 98, 55, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5)
  ;
  border: 1px solid #caa145;
  background:
  -moz-linear-gradient(
    -72deg,
    #ffdeca,
    #ffffff 16%,
    #ffdeca 21%,
    #ffffff 24%,
    #de7345 27%,
    #ffdeca 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffdeca 72%,
    #ffffff 80%,
    #ffdeca 84%,
    #de7345
  );
  background:
  -webkit-linear-gradient(
    -72deg,
    #ffdeca,
    #ffffff 16%,
    #ffdeca 21%,
    #ffffff 24%,
    #de7345 27%,
    #ffdeca 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffdeca 72%,
    #ffffff 80%,
    #ffdeca 84%,
    #de7345
  );
  background:
  -o-linear-gradient(
    -72deg,
    #ffdeca,
    #ffffff 16%,
    #ffdeca 21%,
    #ffffff 24%,
    #de7345 27%,
    #ffdeca 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffdeca 72%,
    #ffffff 80%,
    #ffdeca 84%,
    #de7345
  );
  background:
  linear-gradient(
    -72deg,
    #ffdeca,
    #ffffff 16%,
    #ffdeca 21%,
    #ffffff 24%,
    #de7345 27%,
    #ffdeca 36%,
    #ffffff 45%,
    #ffffff 60%,
    #ffdeca 72%,
    #ffffff 80%,
    #ffdeca 84%,
    #de7345
  );
}

.navbar {
  height: 50px;
  width: 100vw;
  margin: 0 auto;
  font-size: 6px;
  display: flex;
  justify-content: space-between;
  align-items:center;
  align-content:center;
  border-bottom: 1px solid #ccc;
}

.barLink {
  color: white;
  float: left;
  font-family: Muli, sans-serif;
  text-decoration: none;
  margin-left: 25px;
  letter-spacing: 0.75px;
  position: relative;
  top: 40%;
  transition: all 0.35s ease-in-out;
}

.barLink:after {
  color: #992568;
}

.barLink-mobile {
  color: white;
  text-decoration: none;
  margin-right: 10px;
  letter-spacing: 0.75px;
  position: relative;
  font-size: 32px;
  z-index: 199;
  transition: all 0.35s ease-in-out;
}

.barLink:hover {
  color: #992568;
}

.barLink2 {
  color:#fff;
  float: right;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.75px;
  margin-right: 25px;
  position: relative;
  top: 35%;
  transition: all 0.35s ease-in-out;
}

.barLink2-selected {
  color:#992568;
  float: right;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.75px;
  margin-right: 25px;
  position: relative;
  top: 35%;
  transition: all 0.35s ease-in-out;
}

div {
  border:none;
  
  outline:none;
}