.sidebar-nav {
    -webkit-transition: left 0.6s;
    -moz-transition: left 0.6s;
    -ms-transition: left 0.6s;
    -o-transition: left 0.6s;
    transition: left 0.6s;
    transition-timing-function: ease-out;
  }
    
    .sideLogo {
      height: 78px;
      width: 156px;
      position: relative;
      top: 5%;
      display: flex;
      float: left;
      cursor: pointer;
    }
    
    .sidebarLink {
      color: white;
      font-family: Muli, sans-serif;
      text-decoration: none;
      letter-spacing: 0.75px;
      margin-top:  10px;
      position: relative;
      top:10%;
      transition: all 0.75s ease-in-out;
    }
    
    .sidebarLink:hover {
      color: #992568;
    }
    
    .sidebarLink2 {
      color:#fff;
      font-family: Muli, sans-serif;
      text-decoration: none;
      letter-spacing: 0.75px;
      position: relative;
      transition: all 0.75s ease-in-out;
    }
    
    .sidebarLink2-selected {
      color:#992568;
      font-family: Muli, sans-serif;
      text-decoration: none;
      letter-spacing: 0.75px;
      position: relative;
      transition: all 0.75s ease-in-out;
    }
    
    .sidebarLink2:hover {
      color: #992568;
    }
