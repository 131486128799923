.container_forgot {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height:100vw;
    overflow: scroll;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
  }
  
  .form {
    text-align: center;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: relative;
  
    z-index: 7;
  }
  
  .filmmaker-signup-text {
    text-align: center;
    color: #cfcfcf;
  }
  
  .main-content {
    overflow: hidden;
    position: relative;
  
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-flow: column;
    color: #fff;
    text-align: center;
  }
  
  .checkbox {
    width: 15px;
    height: 15px;
    font-size: 13px;
    color: #fff;
    border-radius: 3px;
    border: #fff 1px solid;
    cursor: pointer;
  }
  
  .vignette {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 0px 150px 20px black;
    mix-blend-mode: multiply;
    -webkit-animation: vignette-anim 3s infinite; /* Safari 4+ */
    -moz-animation: vignette-anim 3s infinite; /* Fx 5+ */
    -o-animation: vignette-anim 3s infinite; /* Opera 12+ */
    animation: vignette-anim 3s infinite; /* IE 10+, Fx 29+ */
  }
  
  .noise {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
  
    width: 100%;
    height: 100%;
  
    pointer-events: none;
    opacity: 0.15;
  }
  
  .intro-text {
    color: #992568;
    text-align: center;
    font-size: 20px;
    max-width: 700px;
  }
  
  #sign-off-text {
    font-size: 35px;
  }
  
  .line {
    position: absolute;
    height: 100%;
    width: 1px;
    opacity: 0.1;
    background-color: #000;
  }
  
  .titleCont {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: 'Bellefair', serif;
    width: 100%;
  }
  
  .main-title {
    padding: 0.3em 1em 0.25em;
    font-weight: 400;
    font-size: 40px;
    color: white;
    font-family: 'Bellefair', serif;
    position: relative;
    line-height: 1.3;
    color: #992568;
  }
  
  .overTitle {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .dot {
    width: 3px;
    height: 2px;
    background-color: white;
    position: absolute;
    opacity: 0.3;
  }
  
  @-webkit-keyframes vignette-anim {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
  @-moz-keyframes vignette-anim {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
  @-o-keyframes vignette-anim {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
  @keyframes vignette-anim {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
  }
  
  .oauth {
    text-align: center;
  }
  